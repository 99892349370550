<template>
  <v-card flat>
    <v-row>
      <v-col cols="8">
        <h1>Startgeräte</h1>
        <v-data-table
          :items="geraete(e[0], df, true)"
          :headers="[
            { text: 'Gerät', value: 'name', sortable: false },
            { text: 'Mannschaft', value: 'mannschaft', sortable: false }
          ]"
          :mobile-breakpoint="0"
          :items-per-page="-1"
          hide-default-footer
          class="stbw2023live"
        >
          <template #item.name="{item}">
            <h2>{{ item.name }}</h2>
          </template>
          <template #item.mannschaft="{item}">
            <h2 v-for="t in teamsangeraet(item._discipline)" :key="t.team._id">{{ t.team.name }}</h2>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="4">
        <h1 style="display:block; margin:auto; text-align:center;">QR-Code zur Live-Anzeige</h1>
        <img
          v-if="link"
          :src="link"
          style="width:80%; height:auto; margin:auto; display:block;"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'
import QRCode from 'qrcode'

export default {
  name: 'startgeraet',

  setup (props, context) {
    return {
      ...useCalc(props, context)
    }
  },

  props: {
    es: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    link: null
  }),

  computed: {
    e () {
      return Object.values(this.es)
    },
    teams () {
      return this.e.reduce((acc, curr) => {
        acc.push(...curr.teams)
        return acc
      }, [])
    }
  },

  methods: {
    teamsangeraet (did) {
      return this.teams.filter(t => t._startdiscipline === did)
    },
    async createLink () {
      const link = window.location.href
      this.link = await QRCode.toDataURL(link, { errorCorrectionLevel: 'H', scale: 10 })
    }
  },

  mounted () {
    this.createLink()
  }
}
</script>

<style>

</style>
